import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {makeStyles} from '@material-ui/core/styles';
import favicon from '../favicon.png';
import Copyright from '../components/Copyright';
import routes, {history} from '../router/routes';
import {useAuthContext} from '../hooks/useAuthContext';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  logo: {
    width: '2rem',
    marginRight: theme.spacing(3),
  },
  footer: {
    padding: theme.spacing(4),
    marginTop: 'auto',
    backgroundColor:
      theme.palette.type === 'dark'
        ? theme.palette.grey[800]
        : theme.palette.grey[200],
  },
  grow: {
    flexGrow: 1,
  },
}));

function Layout({children}) {
  const {setAuthToken, authToken} = useAuthContext();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  // setAuthToken(localStorage.getItem('token'));

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goAndClose = route => {
    setAnchorEl(null);
    history.push(route);
  };

  const logout = () => {
    setAnchorEl(null);
    setAuthToken(null);
    history.push(routes.signin);
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="sticky">
        <Toolbar variant="dense">
          <img src={favicon} className={classes.logo} alt="logo" />
          <Typography variant="h6" color="inherit" noWrap>
            Mobilnet - Kontrola dostupnosti skladov
          </Typography>
          <div className={classes.grow} />
          {!!authToken && (
            <>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                onClick={handleClick}
                color="inherit"
              >
                <SettingsIcon />
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={() => goAndClose(routes.home)}>
                  Domov
                </MenuItem>
                <MenuItem onClick={logout}>Odhlásenie</MenuItem>
              </Menu>
            </>
          )}
        </Toolbar>
      </AppBar>
      <main>{children}</main>
      {/* Footer */}
      <footer className={classes.footer}>
        <Copyright />
      </footer>
      {/* End footer */}
    </div>
  );
}

export default Layout;

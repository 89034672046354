import * as React from 'react';

export const AuthContext = React.createContext();

export function AuthProvider({children}) {
  const [authToken, setAuthToken] = React.useState();

  const setTokens = token => {
    localStorage.setItem('token', token);
    setAuthToken(token);
  };
  return (
    <AuthContext.Provider value={{authToken, setAuthToken: setTokens}}>
      {children}
    </AuthContext.Provider>
  );
}

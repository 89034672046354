import {createBrowserHistory as createHistory} from 'history';

export const history = createHistory();

const prefix = '';

const routes = {
  home: `${prefix}/`,
  importer: `${prefix}/importer`,
  signin: `${prefix}/signin`,
};

export default routes;

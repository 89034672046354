import React from 'react';
import {Route} from 'react-router';
import PrivateRoute from './PrivateRoute';
import {Router, Switch} from 'react-router-dom';
import routes, {history} from './routes';
import JwtDecode from 'jwt-decode';
import moment from 'moment';

import Home from '../pages/Home';
import Importer from '../pages/Importer';
import SignIn from '../pages/SignIn';
import {useAuthContext} from '../hooks/useAuthContext';

const getTokenClaims = token => JwtDecode(token);
const checkToken = () => {
  const token = localStorage.getItem('token');
  if (!token || token === 'null' || token === 'undefined') {
    localStorage.removeItem('token');
    return false;
  }
  const tokenClaims = getTokenClaims(token);
  if (moment(tokenClaims.exp * 1000) > moment()) {
    return true;
  } else {
    localStorage.removeItem('token');
    return false;
  }
};

const AppRouter = () => {
  const {setAuthToken} = useAuthContext();
  setAuthToken(checkToken() ? localStorage.getItem('token') : null);
  return (
    <Router history={history}>
      <Switch>
        <Route path={routes.signin} component={SignIn} exact />
        <PrivateRoute path={routes.importer} component={Importer} exact />
        <PrivateRoute path={routes.home} component={Home} exact={false} />
      </Switch>
    </Router>
  );
};

export const PureComponent = AppRouter;

export default AppRouter;

import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import routes, {history} from '../router/routes';
import {useAuthContext} from '../hooks/useAuthContext';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  const {setAuthToken} = useAuthContext();
  const classes = useStyles();
  const [error, setError] = React.useState(null);
  const [isLoading, setLoading] = React.useState(false);
  const [credentials, setCredentials] = React.useState({
    login: null,
    password: null,
  });

  const login = event => {
    event.preventDefault();
    setLoading(true);
    setError(null);
    fetch(`${window.env.BASE_URL}/login`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(credentials),
    })
      .then(response => response.json())
      .then(res => {
        setLoading(false);
        if (res.error) {
          setError(res.message);
        } else {
          setAuthToken(res.access_token);
          setError(null);
          history.push(routes.home);
        }
      });
  };

  const handleInput = (key, e) => {
    const value = (e && e.target && e.target.value) || '';
    setCredentials(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {!isLoading && (
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Prihlásenie
          </Typography>
          {error && (
            <Alert elevation={6} variant="filled" severity="error">
              {error}
            </Alert>
          )}
          <form className={classes.form} noValidate onSubmit={login}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={e => handleInput('login', e)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Heslo"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={e => handleInput('password', e)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Prihlásiť sa
            </Button>
          </form>
        </div>
      )}
    </Container>
  );
}

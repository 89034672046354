import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import routes from './routes';

export const PrivateRoute = ({component: Component, ...rest}) => {
  const authToken = localStorage.getItem('token');
  return (
    <Route
      {...rest}
      component={props =>
        authToken !== 'null' ? (
          // User is authenticated, route to the component (home or ref route)
          <Component {...props} />
        ) : (
          <Redirect to={routes.signin} />
        )
      }
    />
  );
};

export const PureComponent = PrivateRoute;

export default PrivateRoute;

import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import packageJson  from '../../package.json';

const appVersion = packageJson.version;
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://https://www.mobilnet.sk/">
        Mobilnet.sk
      </Link>{' '}
      {new Date().getFullYear()}
      {`. | v${appVersion}`}
    </Typography>
  );
}
export default Copyright;

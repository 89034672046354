import React from 'react';
import Typography from '@material-ui/core/Typography';
import grey from '@material-ui/core/colors/grey';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Link as RouterLink} from 'react-router-dom';
import Link from '@material-ui/core/Link';
import {makeStyles} from '@material-ui/core/styles';
import cx from 'classnames';
import routes from '../router/routes';
import moment from 'moment';
import {STOCKS} from '../constants';

moment.locale('sk');

const useStyles = makeStyles((theme) => ({
  gridListHorizontal: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  cardContent: {
    padding: theme.spacing(3),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    margin: theme.spacing(0),
  },
  disabled: {
    color: grey[500],
  },
}));
function Importer() {
  const classes = useStyles();
  const [storageStatus, setStorageStatus] = React.useState({
    [STOCKS[0]]: null,
    [STOCKS[1]]: null,
    [STOCKS[2]]: null,
  });
  const [isLoading, setLoading] = React.useState(false);
  const [importingStorage, setStorage] = React.useState(null);
  const [modalStorage, setStorageToModal] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    fetch(`${window.env.BASE_URL}/storage/status`, {
      headers: {
        'Content-type': 'multipart/form-data',
        Accept: 'application/json',
      },
      method: 'GET',
    })
      .then((response) => response.json())
      .then((res) => {
        if (res && res.stocks) {
          const keys = Object.keys(res.stocks);
          Object.values(res.stocks).map((data, index) =>
            setStorageStatus((prev) => ({
              ...prev,
              [keys[index]]: moment(data.date).format('DD.MM.YYYY HH:mm:ss'),
            }))
          );
        }
      })
      .catch((e) => {
        alert(e);
      });
  }, []);

  const handleClickOpen = (storageId) => {
    setStorageToModal(storageId);
    if (!isLoading) setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const importStorage = (storageId) => {
    if (!isLoading) {
      setLoading(true);
      setStorage(storageId);
      fetch(`${window.env.BASE_URL}/storage/${storageId}/fill`, {
        headers: {
          'Content-type': 'multipart/form-data',
          Accept: 'application/json',
        },
        method: 'GET',
      })
        .then((response) => response.json())
        .then(() => {
          setLoading(false);
          setStorage(null);
          setStorageStatus((prev) => ({
            ...prev,
            [storageId]: moment().utc().format('DD.MM.YYYY HH:mm:ss'),
          }));
          setOpen(false);
        })
        .catch((e) => {
          setLoading(false);
          setStorage(null);
          setOpen(false);
          alert(e);
        });
    }
  };

  const confirmDialog = (storageId) => {
    return (
      <Dialog
        key={`modal_${storageId}`}
        open={open}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{cursor: 'move'}} id="draggable-dialog-title">
          Potvrdenie
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p>
              Ste si istý, že chcete spustiť ručný import pre sklad{' '}
              <strong>{storageId}</strong>?
            </p>
            <small>
              Tento proces môže zaťažiť server a tým spomaliť beh skupiny
              stránok <pre>mobilnet.sk</pre>.
            </small>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Zrušiť
          </Button>
          <Button
            onClick={() => {
              setLoading(false);
              importStorage(storageId);
              handleClose();
            }}
            color="primary"
          >
            Potvrdiť
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const storageBox = (storageId) => {
    return (
      <React.Fragment key={`importer_storage_f_${storageId}`}>
        <Grid item key={`importer_storage_${storageId}`} xs={12} sm={4} md={4}>
          <Card
            className={classes.card}
            onClick={() => handleClickOpen(storageId)}
          >
            <CardActionArea>
              <CardContent
                className={cx(classes.cardContent, {
                  [classes.disabled]: isLoading,
                })}
              >
                <Grid container justify="space-between" spacing={2}>
                  <Grid key={`importer_icon_s${storageId}`} item>
                    <ImportExportIcon style={{fontSize: 40}} />
                  </Grid>
                  <Grid key={`importer_text_s${storageId}`} item>
                    <Typography gutterBottom variant="h5" component="h5">
                      {`Sklad ${storageId}`}
                    </Typography>
                  </Grid>
                  <Grid key={`importer_loading_s${storageId}`} item>
                    {importingStorage === storageId && <CircularProgress />}
                  </Grid>
                </Grid>

                <small>Posledná aktualizacia: {storageStatus[storageId]}</small>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </React.Fragment>
    );
  };

  return (
    <Container className={classes.cardGrid} maxWidth="md">
      <p>
        <Link component={RouterLink} to={routes.home}>
          Späť
        </Link>
      </p>
      <Grid container spacing={4} className={classes.gridListHorizontal}>
        {STOCKS.map((s) => storageBox(s))}
      </Grid>

      {confirmDialog(modalStorage)}
    </Container>
  );
}
export default Importer;

import React from "react";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import StorageCard from "../components/StorageCard";

import { DropzoneArea } from "material-ui-dropzone";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  button: {
    margin: theme.spacing(0),
  },
  dropzone: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));

function Home() {
  const classes = useStyles();
  const [isLoading, setLoading] = React.useState(false);
  const [storageState, setStorage] = React.useState('katana');
  const [file, storeFile] = React.useState([]);

  const send = (storage, files) => {
    setLoading(true);
    var data = new FormData();
    data.append("file", files[0]);
    data.append("storage", storage);
    let url = `${window.env.BASE_URL}/storage`;
    axios.post(url,data, {headers: {
      'Content-Type': 'multipart/form-data'
    }})
    .then(({data}) => {
      return new Blob([data]);
    })
    .then((myFile) => {
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      const url = window.URL.createObjectURL(myFile);
      a.href = url;
      a.download = "final.csv";
      a.click();
      window.URL.revokeObjectURL(url);
      setLoading(false);
      setStorage(null);
      storeFile([]);
      return myFile;
    });
  };
  return (
    <>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {!isLoading && (
        <Container className={classes.cardGrid} maxWidth="md">
          
          <Grid container spacing={4}>
            <StorageCard
              key={`storage_katana`}
              classes={classes}
              storage={'1 (Katana)'}
              setStorage={() => { setStorage('katana') }}
              isActive={!!storageState}
            />
          </Grid>
          <div className={classes.dropzone}>
            <DropzoneArea
              onChange={(e) => storeFile(e)}
              dropzoneText="Vybrať alebo presunúť CSV súbor na úpravu"
              acceptedFiles={[".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values"]}
            />
          </div>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            fullWidth
            endIcon={<CloudUploadIcon />}
            disabled={!storageState || file.length === 0}
            onClick={() => {
              send(storageState, file);
            }
            }
          >
            Odoslať
          </Button>
        </Container>
      )}
    </>
  );
}
export default Home;

import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import StoreTwoToneIcon from '@material-ui/icons/StoreTwoTone';

function StorageCard({classes, isActive, storage, setStorage}) {
  return (
    <Grid item key={`storage_${storage}`} xs={12} sm={4} md={4}>
      <Card className={classes.card} onClick={setStorage}>
        <CardActionArea>
          <CardContent className={classes.cardContent}>
            <Grid item xs container direction="row" spacing={2}>
              <Grid item key={`storage_logo_${storage}`} xs={12} sm={6} md={6}>
                <StoreTwoToneIcon
                  style={{fontSize: 100}}
                  color={isActive ? 'secondary' : 'disabled'}
                />
              </Grid>
              <Grid
                item
                key={`storage_content_${storage}`}
                xs={12}
                sm={6}
                md={6}
              >
                <br />
                <Typography gutterBottom variant="h5" component="h5">
                  {`Sklad číslo ${storage} `}
                </Typography>
              </Grid>
            </Grid>

            <Typography variant="subtitle1" color="textSecondary">
              Sklad na kontrolu dostupnosti produktov obsiahnutých v CSV.
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
}

export default StorageCard;

import React from 'react';
import Layout from './containers/Layout';
import AppRouter from './router/AppRouter';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import {AuthProvider} from './providers/AuthProvider';

const theme = createMuiTheme({
  palette: {
    primary: {main: '#000'},
    secondary: {main: '#e2001a'},
  },
});
function App() {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <Layout>
          <AppRouter />
        </Layout>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
